


import React from "react";
import Navbar from "../Navbar"
import Footer from "../Footer"
import shape from "../../images/shape/shape_26.png";
import Img1 from "../../images/media/mutualFund.jpg";
// import BannerImg1 from "../../images/lazy.png";

import { Link } from "react-router-dom";

const MutualFund = () => {

    return (
        <>

            <Navbar />





            <div
                class="inner-banner-one pt-225 lg-pt-200 md-pt-150 pb-100 md-pb-70 position-relative"
                style={{ backgroundImage: `url(${Img1})` }}
            >
                <div class="container position-relative">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <h1
                                class="hero-heading d-inline-block position-relative me-xxl-4 me-lg-5">
                                Services
                                <img src={shape} alt="img" />
                            </h1>
                        </div>
                        <div class="col-xl-4 col-lg-5 ms-auto">
                            <p
                                class="text-white text-lg mb-70 lg-mb-40">Offering
                                solutions & services to address a spectrum
                                of financial issues</p>
                            <ul class="style-none d-inline-flex pager">
                                <li><Link to="/">Home</Link></li>
                                <li>/</li>
                                <li>MutualFund</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


            {/* <div className="block-feature-one position-relative pt-75">
                <div className="upper-wrapper mb-110 lg-mb-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 wow fadeInUp">
                                <div className="card-style-one d-flex w-100 mb-35">
                                    <div
                                        className="icon tran3s rounded-circle d-flex align-items-center justify-content-center">
                                        <img
                                            src={BannerImg1}
                                            alt="img"
                                            className="lazy-img" />
                                    </div>
                                    <div className="text ps-4">
                                        <h4 className="fw-bold">Low Fees</h4>
                                        <p className="pe-xl-4"></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
                                <div className="card-style-one d-flex w-100 mb-35">
                                    <div
                                        className="icon tran3s rounded-circle d-flex align-items-center justify-content-center">
                                        <img
                                            src={BannerImg1}
                                            alt="img"
                                            className="lazy-img" />
                                    </div>
                                    <div className="text ps-4">
                                        <h4 className="fw-bold">Expert Advisor</h4>
                                        <p className="pe-xl-4"></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.2s">
                                <div className="card-style-one d-flex w-100 mb-35">
                                    <div
                                        className="icon tran3s rounded-circle d-flex align-items-center justify-content-center">
                                        <img
                                            src={BannerImg1}
                                            alt="img"
                                            className="lazy-img" />
                                    </div>
                                    <div className="text ps-4">
                                        <h4 className="fw-bold">Effective Support</h4>
                                        <p className="pe-xl-4"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="position-relative">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="title-one text-center text-md-start mb-30 sm-mb-10">
                                    <h2>Provide quality Services.</h2>
                                </div>
                            </div>
                        </div>
                        <div className="justify-content-center d-flex">
                            <div className="col-lg-4 col-md-6 d-flex wow fadeInUp p-2">
                                <div className="card-style-two vstack tran3s w-100 mt-30">
                                    <img
                                        src={BannerImg1}
                                        alt="img"
                                        className="lazy-img icon me-auto" />
                                    <h4 className="fw-bold mt-30 mb-25">Mutual fund</h4>
                                    <p className="mb-20">
                                        A mutual fund is an investment vehicle that pools money from
                                        various investors and invests it in a diversified portfolio
                                        of stocks, bonds, or other securities. The mutual fund is
                                        managed by a professional fund manager who buys and sells
                                        securities on behalf of the investors.
                                    </p>

                                    <Link to="/MutualFund" className="arrow-btn tran3s mt-auto stretched-link">
                                        <img
                                            src={BannerImg1}
                                            alt="img"
                                            className="lazy-img" />

                                    </Link>

                                </div>
                            </div>
                            <div
                                className="col-lg-4 col-md-6 d-flex wow fadeInUp p-2"
                                data-wow-delay="0.1s">
                                <div className="card-style-two vstack tran3s w-100 mt-30">
                                    <img
                                        src={BannerImg1}
                                        alt="img"
                                        className="lazy-img icon me-auto" />
                                    <h4 className="fw-bold mt-30 mb-25">Insurance</h4>
                                    <p className="mb-20">
                                        Insurance is a contract, represented by a policy, in which
                                        an individual or entity receives financial protection or
                                        reimbursement against losses from an insurance company. The
                                        company pools clients' risks to make payments more
                                        affordable for the insured.
                                    </p>

                                    <Link to="/Insurance" className="arrow-btn tran3s mt-auto stretched-link">
                                        <img
                                            src={BannerImg1}
                                            alt="img"
                                            className="lazy-img" />

                                    </Link>

                                </div>
                            </div>
                            <div
                                className="col-lg-4 col-md-6 d-flex wow fadeInUp p-2"
                                data-wow-delay="0.2s">
                                <div className="card-style-two vstack tran3s w-100 mt-30">
                                    <img
                                        src={BannerImg1}
                                        alt="img"
                                        className="lazy-img icon me-auto" />
                                    <h4 className="fw-bold mt-30 mb-25">Equity Investment</h4>
                                    <p className="mb-20">
                                        We work continuous of Research on Fundamentals – Value,
                                        Growth & Technical, which gives you Opportunity base theme
                                        for Investing
                                    </p>
                                    <Link to="/EquityInvestment" className="arrow-btn tran3s mt-auto stretched-link">
                                        <img
                                            src={BannerImg1}
                                            alt="img"
                                            className="lazy-img" />

                                    </Link>

                                </div>
                            </div>
                            <div
                                className="col-lg-4 col-md-6 d-flex wow fadeInUp p-2"
                                data-wow-delay="0.2s">
                                <div className="card-style-two vstack tran3s w-100 mt-30">
                                    <img
                                        src={BannerImg1}
                                        alt="img"
                                        className="lazy-img icon me-auto" />
                                    <h4 className="fw-bold mt-30 mb-25">Capital Protection</h4>
                                    <p className="mb-20">
                                        World's Best Strategy Blend with Investment through MIP &
                                        transfer interest income to balanced funds. Best for
                                        moderate F
                                    </p>
                                    <Link to="/LoanServices" className="arrow-btn tran3s mt-auto stretched-link">
                                        <img
                                            src={BannerImg1}
                                            alt="img"
                                            className="lazy-img" />

                                    </Link>

                                </div>
                            </div>
                        </div>

                        <div className="section-subheading sm-mt-40">
                            <p className="text-lg">
                                Inciddnt ut labore et dolor magna aliu. enim ad mim venam, quis
                                nostru
                            </p>
                            <Link to="/MutualFund" className="btn-three icon-link">
                                <span>See All Services</span>
                                <img
                                    src={BannerImg1}
                                    alt="img"
                                    className="lazy-img icon ms-1" />
                            </Link>

                        </div>
                    </div>
                </div>
            </div> */}

            <div className="main-page-wrapper container card rounded shadow-sm mt-4 p-4 mb-4 bx-shadow  rounded">
                <div class="text-feature-one lg-mt-80">
                    <div class="container">
                        <div>
                            <h2>Mutual Funds</h2>
                        </div>
                        <p>
                            A mutual fund is an investment vehicle where many investors pool
                            their money to earn returns on their capital over a period. This
                            corpus of funds is managed by an investment professional known as a
                            fund manager or portfolio manager. It is his/her job to invest the
                            corpus in different securities such as bonds, stocks, gold and other
                            assets and seek to provide potential returns. The gains (or losses)
                            on the investment are shared collectively by the investors in
                            proportion to their contribution to the fund.
                        </p>
                        <div>
                            <h3>Why invest in mutual funds ?</h3>
                            <p>
                                There are many benefits of investing in mutual funds. Here are
                                some important ones - <b> Professional expertise</b>
                            </p>
                            <p>
                                Consider a situation where you purchase a new car. But the catch
                                here is that you don’t know how to drive. Now, you have two
                                options:
                            </p>
                            <ol>
                                <li>you can learn how to drive</li>
                                <li>you can hire a full-time driver</li>
                            </ol>
                            <p>
                                In the first scenario, you would have to take driving lessons,
                                pass the driving test and obtain a license. But if you don’t have
                                the time for driving classes, it is better to opt for a driver.
                                Same is the case with investments. Investing in financial markets
                                requires a certain amount of skill. You need to research the
                                market and analyse the best options available. You need knowledge
                                on matters such as macro economy, sectors, company financials,
                                from an asset class perspective. This requires a significant
                                amount of time and commitment from you.
                            </p>
                            <p>
                                But if you don’t have the skill or the time to delve deep into the
                                market, investing in mutual funds can be an excellent alternative.
                                Here, a professional fund manager takes care of your investments
                                and strives hard to provide reasonable returns. And just as you
                                would pay the driver for his chauffeuring services, you have to
                                pay specific fees for the professional management of your mutual
                                fund investments. Returns
                            </p>
                            <p>
                                One of the biggest mutual fund benefits is that you have the
                                opportunity to earn potentially higher returns than traditional
                                investment options offering assured returns. This is because the
                                returns on mutual funds are linked to the market’s performance.
                                So, if the market is on a bull run and it does exceedingly well,
                                the impact would be reflected in the value of your fund. However,
                                a poor performance in the market could negatively impact your
                                investments. Unlike traditional investments ,mutual funds do not
                                assure capital protection. So do your research and invest in funds
                                that can help you meet your financial goals at the right time in
                                life. Diversification
                            </p>
                            <p>
                                You may have heard the saying: Don’t put all your eggs in one
                                basket. This is a famous mantra to remember when you invest your
                                money. When you invest only in a single asset, you could risk a
                                loss if the market crashes. However, you can avoid this problem by
                                investing in different asset classes and diversifying your
                                portfolio. If you were investing in stocks and had to diversify,
                                you would have to select at least ten stocks carefully from
                                different sectors. This can be a lengthy, time-consuming process.
                                But when you invest in mutual funds, you achieve diversification
                                instantly. For instance, if you invest in a mutual fund that
                                tracks the BSE Sensex, you would get access to as many as 30
                                stocks across sectors in a single fund. This could reduce your
                                risk to a large extent. Tax benefits
                            </p>
                            <p>
                                Mutual fund investors can claim a tax deduction of up to Rs. 1.5
                                lakh by investing in Equity Linked Savings Schemes (ELSS). This
                                tax benefit is eligible under Section 80C of the Income Tax Act.
                                ELSS funds come with a lock-in period of 3 years. Hence, if you
                                invest in ELSS funds, you can only withdraw your money after the
                                lock-in period ends. Another tax benefit is indexation benefit
                                available on debt funds. In case of traditional products, all
                                interest earned is subject to tax. However, in case of debt mutual
                                funds, only the returns earned over and above the inflation rate
                                (embedded in cost inflation index  [CII] ) are subject to tax. This
                                could also help investors earn higher post tax returns.
                            </p>
                        </div>
                    </div>

                </div>

            </div>

            <Footer />
        </>
    );
}

export default MutualFund;