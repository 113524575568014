


import React from "react";
import Navbar from "../Navbar"
import Footer from "../Footer"
import shape from "../../images/shape/shape_26.png";
import Img1 from "../../images/media/realEstate.jpg";
import BannerImg1 from "../../images/lazy.png";


import { Link } from "react-router-dom";

const MutualFund = () => {

    return (
        <>

            <Navbar />


            <div
                class="inner-banner-one pt-225 lg-pt-200 md-pt-150 pb-100 md-pb-70 position-relative"
                style={{ backgroundImage: `url(${Img1})` }}
            >
                <div class="container position-relative">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <h1
                                class="hero-heading d-inline-block position-relative me-xxl-4 me-lg-5">
                                Services
                                <img src={shape} alt="img" />
                            </h1>
                        </div>
                        <div class="col-xl-4 col-lg-5 ms-auto">
                            <p
                                class="text-white text-lg mb-70 lg-mb-40">Offering
                                solutions & services to address a spectrum
                                of financial issues</p>
                            <ul class="style-none d-inline-flex pager">
                                <li><Link to="/">Home</Link></li>
                                <li>/</li>
                                <li>RealEstate</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="main-page-wrapper container card rounded shadow-sm mt-4 p-4 mb-4 bx-shadow  rounded">
                <div class="text-feature-one  lg-mt-80">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-5 wow fadeInLeft">
                                <img src={"https://www.prameshwealth.com/loan/r1.jpg"} alt="img"
                                />
                            </div>
                            <div class="col-lg-6 ms-auto">
                                <div class="row">
                                    <div class="d-flex wow fadeInUp">
                                        <div
                                            class="card-style-twelve mb-40 lg-mb-20">
                                            <div
                                                class="icon tran3s d-flex align-items-center justify-content-center rounded-circle">
                                                <img src={BannerImg1}
                                                    data-src="images/icon/icon_55.png"
                                                    alt="img"
                                                    class="lazy-img" />
                                            </div>
                                            <h4 class="fw-bold mt-20 mb-15">BUY

                                            </h4>
                                            <p>Real estate is a class of
                                                "real property" that includes land
                                                and anything permanently attached to
                                                it, whether natural or man-made.You
                                                can invest in real estate directly
                                                by purchasing a home, rental
                                                property or other property, or
                                                indirectly through a real estate
                                                investment trust (REIT).

                                            </p>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6 ms-auto">
                                <div class="row">
                                    <div class="d-flex wow fadeInUp">
                                        <div
                                            class="card-style-twelve mb-40 lg-mb-20">
                                            <div
                                                class="icon tran3s d-flex align-items-center justify-content-center rounded-circle">
                                                <img src={BannerImg1}
                                                    data-src="images/icon/icon_55.png"
                                                    alt="img"
                                                    class="lazy-img" />
                                            </div>
                                            <h4 class="fw-bold mt-20 mb-15">SELL

                                            </h4>
                                            <p>The most popular way is to buy an
                                                investment property and slowly build
                                                up your portfolio. Generally, there
                                                are two primary ways to make money
                                                from real estate assets —
                                                appreciation, which is an increase
                                                in property value over a period of
                                                time, and rental income collected by
                                                renting out the property to tenants.

                                            </p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-lg-5 wow fadeInLeft">
                                <img src={"https://www.prameshwealth.com/loan/r2.jpg"} alt="img"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-5 wow fadeInLeft">
                                <img src={"https://www.prameshwealth.com/loan/r3.jpg"} alt="img"
                                />
                            </div>
                            <div class="col-lg-6 ms-auto">
                                <div class="row">
                                    <div class="d-flex wow fadeInUp">
                                        <div
                                            class="card-style-twelve mb-40 lg-mb-20">
                                            <div
                                                class="icon tran3s d-flex align-items-center justify-content-center rounded-circle">
                                                <img src={BannerImg1}
                                                    data-src="images/icon/icon_55.png"
                                                    alt="img"
                                                    class="lazy-img" />
                                            </div>
                                            <h4 class="fw-bold mt-20 mb-15">RENT

                                            </h4>
                                            <p>Rental income is any payment you
                                                receive for the use or occupation of
                                                property. You must report rental
                                                income for all your properties. In
                                                addition to amounts you receive as
                                                normal rent payments, there are
                                                other amounts that may be rental
                                                income and must be reported on your
                                                tax return.

                                            </p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>

            <Footer />
        </>
    );
}

export default MutualFund;