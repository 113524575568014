import React from "react";

const BannerTwo = () => {
  return (
    <>
      <div className="text-center position-relative pb-40 md-pb-20 bg-white">
        <div className="row align-items-center">
          <div className="text-center text-md-end">
            <div className="text-center">
              <h2 className="pe-md-4 sm-pb-50 mt-4">
                Let’s <span>Discuss & Start</span>
              </h2>

              <p>Welcome to Pramesh Wealth Pvt Ltd</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BannerTwo;
