


import React from "react";
// import BannerImg1 from "../images/lazy.png";
import profileImg from "../images/media/profile.jpg"
import { Link } from "react-router-dom";

const Advisor = () => {

    return (
        <>
            <div class="team-section-one mt-150 lg-mt-80 pt-300">
                <div class="container">
                    <div class="position-relative">
                        <div class="title-one mb-40 lg-mb-20 wow fadeInUp">
                            <h2>Our Advisor.</h2>
                        </div>

                        <div class="row">
                            <div class="col-lg-3 col-sm-6 wow fadeInUp">
                                <div class="card-style-four mt-35">
                                    <div
                                        class="media d-flex align-items-center justify-content-center position-relative overflow-hidden ">
                                        <img
                                            src={profileImg} alt="img"
                                            class="lazy-img w-100" />
                                        <Link to="/Team" className="round-btn rounded-circle d-flex align-items-center justify-content-center tran3s"> <i
                                            class="bi bi-arrow-up-right"></i></Link>


                                    </div>
                                    <h4 class="fw-500 pt-20 m0">Mr. Ramchandra R. Patel</h4>
                                    <div class="fs-6">Founder & Managing Director</div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                                <div class="card-style-four mt-35">
                                    <div
                                        class="media d-flex align-items-center justify-content-center position-relative overflow-hidden">
                                        <img
                                            src={profileImg}
                                            alt="img"
                                            class="lazy-img w-100" />
                                        <Link to="/Team" className="round-btn rounded-circle d-flex align-items-center justify-content-center tran3s"> <i
                                            class="bi bi-arrow-up-right"></i></Link>
                                    </div>
                                    <h4 class="fw-500 pt-20 m0">Mr. Ronak N. Bhatt  </h4>
                                    <div class="fs-6">Director</div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.2s">
                                <div class="card-style-four mt-35">
                                    <div
                                        class="media d-flex align-items-center justify-content-center position-relative overflow-hidden">
                                        <img
                                            src={profileImg}
                                            alt="img"
                                            class="lazy-img w-100" />
                                        <Link to="/Team" className="round-btn rounded-circle d-flex align-items-center justify-content-center tran3s"> <i
                                            class="bi bi-arrow-up-right"></i></Link>
                                    </div>
                                    <h4 class="fw-500 pt-20 m0">Mr. Ashay J. Shah</h4>
                                    <div class="fs-6">Director</div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
                                <div class="card-style-four mt-35">
                                    <div
                                        class="media d-flex align-items-center justify-content-center position-relative overflow-hidden">
                                        <img
                                            src={profileImg}
                                            data-src="images/media/img_12.jpg"
                                            alt="img"
                                            class="lazy-img w-100" />
                                        <Link to="/Team" className="round-btn rounded-circle d-flex align-items-center justify-content-center tran3s"> <i
                                            class="bi bi-arrow-up-right"></i></Link>
                                    </div>
                                    <h4 class="fw-500 pt-20 m0">Mr.x</h4>
                                    <div class="fs-6">Senior Officer</div>
                                </div>
                            </div>
                        </div>

                        <div class="section-btn sm-mt-40">
                            <Link to="/Team" className="btn-five icon-link"> <span className="text">See all Experts</span>
                                <div
                                    className="icon tran3s rounded-circle d-flex align-items-center justify-content-center">
                                    <i className="bi bi-arrow-up-right"></i>
                                </div></Link>


                        </div>
                    </div>
                </div>
            </div >


        </>
    );
}

export default Advisor;